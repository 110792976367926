<template>
  <div>
    <asideBar />
    <div class="main-content flex-fill" :style="{ 'margin-left': $store.state.isMobile ? '0px' : $store.state.collapse ? '200px' : '60px' }">
      <div class="placeholder"></div>
      <div id="content" class="container customize-width px-md-5 mt-4">
        <div class="content-body">
          <div class="content-wrap">
            <div class="content-layout">
              <main>
                <div class="block-header mb-3 mb-md-4">
                  <h4 class="block-title"><i class="io io-renwu icon-fw"></i>最新资讯</h4>
                </div>
                <div class="mb-4 category-swiper">
                  <div class="swiper-wrapper">
                    <a
                      style="width: auto"
                      class="btn btn-search mr-2 text-gray  swiper-slide"
                      v-for="(item, index) in classList"
                      :key="item.id"
                      @click="clickA(index)"
                      :class="currentIndex == index ? 'current' : ''"
                      >{{ item.name }}</a
                    >
                  </div>
                </div>
                <div class="row io-mx-n2">
                  <div
                    class="url-card post-card io-px-2 col-1a col-sm-2a col-xl-3a col-xxl-4a mb-3 mb-md-4"
                    v-for="item of zionList"
                    :kye="item.id"
                    @click="clickJump('/zixun?id=' + item.id)"
                  >
                    <div class="card-post list-item url-body">
                      <div class="url-content">
                        <div class="list-content py-0">
                          <div class="list-body">
                            <a class="post-name overflowClip_2"
                              ><strong>{{ item.title }}</strong></a
                            >
                          </div>
                          <div class="list-footer">
                            <div class="d-flex flex-fill align-items-center">
                              <div class="text-sm text-muted">{{ daysSinceTimestamp(item.createtime) }}</div>
                              <div class="flex-fill"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="posts-nav mb-3 mb-md-4">
                  <el-pagination
                    background
                    layout="prev, pager, next"
                    :total="total"
                    @prev-click="prevClick"
                    @next-click="nextClick"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <asideDrawer />
  </div>
</template>
<script>
import asideBar from '@/views/layout/components/aside'
import asideDrawer from '@/views/layout/components/asideDrawer'
import { getFlNews, getNews } from '@/api/http'
export default {
  components: {
    asideBar,
    asideDrawer
  },
  data() {
    return {
      classList: [],
      currentIndex: 0,
      zionList: [],
      page: 1,
      total: 0
    }
  },

  created() {
    this.getList()
  },
  destroyed() {},
  methods: {
    async getList() {
      const data = await getFlNews()
      this.classList = data.data.data
      var query = {
        category_id: this.classList[this.currentIndex].id
      }
      const res = await getNews(query)
      this.zionList = res.data.data.rows
      this.total = res.data.data.total
    },
    clickA(val) {
      this.currentIndex = val
      this.getList()
    },
    clickJump(val) {
      this.$router.push(val)
    },
    prevClick(val) {
      this.page = val
      this.getList()
    },
    nextClick(val) {
      this.page = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getList()
    },
    daysSinceTimestamp(timestamp) {
      const now = Math.floor(Date.now() / 1000) // 当前时间的时间戳（秒）
      const difference = now - timestamp // 时间差（秒）

      const minutes = Math.floor(difference / 60)
      const hours = Math.floor(difference / 3600)
      const days = Math.floor(difference / 86400)
      const months = Math.floor(days / 30)

      if (months > 0) {
        return `${months}月前`
      } else if (days > 0) {
        return `${days}天前`
      } else if (hours > 0) {
        return `${hours}小时前`
      } else if (minutes > 0) {
        return `${minutes}分钟前`
      } else {
        return '刚刚' // 小于1分钟
      }
    }
  }
}
</script>
<style scoped>
@import url('../css/bootstrap.css');
@import url('../css/style.css');

.category-swiper {
  white-space: nowrap;
  /* 确保内容在同一行显示 */
  overflow: hidden;
  /* 隐藏滚动条 */
  position: relative;
}

.category-swiper::before {
  content: '';
  display: block;
  height: 1px;
  /* 伪元素的高度可以是任意小值 */
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  /* 确保覆盖整个宽度 */
}

.swiper-wrapper {
  overflow-x: auto;
  /* 启用水平滚动 */
  padding-bottom: 17px;
  /* 额外的空间来防止内容被覆盖 */
}

.url-card {
  cursor: pointer;
}
</style>
